.banner {
    background: #7F7F7F;
    color: #fff;
}
.player-image {
    max-width: 50px;
}

.player-position {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 46px;
    height: 32px;
}

.division {
    padding: 5px;
}

.qb {
    background: #ff2a6d;
}

.rb {
    background: #00ceb8;
}

.wr {
    background: #58a7ff;
}

.te {
    background: #ffae58;
}

.dl {
    background: #ff795a;
}

.dt {
    background: #ff795a;
}

.db {
    background: #ff7cb6;
}

.cb {
    background: #ff7cb6;
}

.lb {
    background: #6d7df5;
}

.de {
    background: #6d7df5;
}

.card {
    margin: 5px;
}

.card-title {
    padding-top: 5px;
    padding-left: 5px;
}

.nowrap {
    white-space: nowrap;
}

.playoffTieButton {
    width: 15px;
    white-space: nowrap;
}
.playoffTeamButton {
    width: 130px;
    white-space: nowrap;
}

.playoffTeamLeft {
    text-align: left;
}
.playoffTeamRight {
    text-align: right;
}

.reallySmallTable {
    @media (width <= 576px) {
        font-size: 80%;
    }
}